// extracted by mini-css-extract-plugin
export var bookMockUp__container = "zeal-pos-module--bookMockUp__container--8ffe5";
export var bookMockUp__content = "zeal-pos-module--bookMockUp__content--45aa2";
export var bookMockUp__header = "zeal-pos-module--bookMockUp__header--c2e8d";
export var bookMockUp__imgContainer = "zeal-pos-module--bookMockUp__imgContainer--05d6a";
export var bookMockUp__input = "zeal-pos-module--bookMockUp__input--aa483";
export var bookMockUp__wrapper = "zeal-pos-module--bookMockUp__wrapper--2233e";
export var customerSection = "zeal-pos-module--customerSection--fc07b";
export var customerSection__content = "zeal-pos-module--customerSection__content--c8a8c";
export var customerSection__description = "zeal-pos-module--customerSection__description--78164";
export var customerSection__description2 = "zeal-pos-module--customerSection__description2--6ab07";
export var customerSection__item = "zeal-pos-module--customerSection__item--65684";
export var customerSection__itemContent = "zeal-pos-module--customerSection__itemContent--6af03";
export var customerSection__itemHeader = "zeal-pos-module--customerSection__itemHeader--89edc";
export var customerSection__title = "zeal-pos-module--customerSection__title--a089b";
export var dataSecurity__container = "zeal-pos-module--dataSecurity__container--3ce50";
export var dataSecurity__content = "zeal-pos-module--dataSecurity__content--2cccf";
export var dataSecurity__header = "zeal-pos-module--dataSecurity__header--369a0";
export var dataSecurity__item = "zeal-pos-module--dataSecurity__item--b2ac6";
export var dataSecurity__items = "zeal-pos-module--dataSecurity__items--60d51";
export var firstJourney__content = "zeal-pos-module--firstJourney__content--0c811";
export var firstJourney__features = "zeal-pos-module--firstJourney__features--ac715";
export var firstJourney__header = "zeal-pos-module--firstJourney__header--4e1fc";
export var firstJourney__id = "zeal-pos-module--firstJourney__id--b6925";
export var firstJourney__p = "zeal-pos-module--firstJourney__p--ee07a";
export var firstJourney__text = "zeal-pos-module--firstJourney__text--547a1";
export var firstJourney__wrapper = "zeal-pos-module--firstJourney__wrapper--ad38a";
export var firstTime__btn = "zeal-pos-module--firstTime__btn--259e1";
export var firstTime__container = "zeal-pos-module--firstTime__container--61e08";
export var firstTime__content = "zeal-pos-module--firstTime__content--d81f2";
export var firstTime__header = "zeal-pos-module--firstTime__header--bc0f1";
export var firstTime__images = "zeal-pos-module--firstTime__images--4036d";
export var firstTime__info = "zeal-pos-module--firstTime__info--efdb0";
export var firstTime__wrapper = "zeal-pos-module--firstTime__wrapper--56c05";
export var fullWidth = "zeal-pos-module--fullWidth--0fd7a";
export var heroSection__backgroundImg = "zeal-pos-module--heroSection__backgroundImg--2b095";
export var heroSection__button = "zeal-pos-module--heroSection__button--5dca1";
export var heroSection__button__wrapper = "zeal-pos-module--heroSection__button__wrapper--b23df";
export var heroSection__content = "zeal-pos-module--heroSection__content--388e2";
export var heroSection__description = "zeal-pos-module--heroSection__description--f01a9";
export var heroSection__ellipse = "zeal-pos-module--heroSection__ellipse--9e8b2";
export var heroSection__hero_img_desktop = "zeal-pos-module--heroSection__hero_img_desktop--a8840";
export var heroSection__title = "zeal-pos-module--heroSection__title--b73da";
export var heroSection__wrapper = "zeal-pos-module--heroSection__wrapper--bd689";
export var loyalty__container = "zeal-pos-module--loyalty__container--8aed7";
export var loyalty__header = "zeal-pos-module--loyalty__header--b0440";
export var loyalty__img = "zeal-pos-module--loyalty__img--07608";
export var loyalty__imgContainer = "zeal-pos-module--loyalty__imgContainer--149ba";
export var loyalty__itemContent = "zeal-pos-module--loyalty__itemContent--00cc9";
export var loyalty__wrapper = "zeal-pos-module--loyalty__wrapper--0a2f1";
export var marketing = "zeal-pos-module--marketing--a46d9";
export var marketing__btn = "zeal-pos-module--marketing__btn--901fe";
export var marketing__content = "zeal-pos-module--marketing__content--dd8f9";
export var marketing__description = "zeal-pos-module--marketing__description--77bc0";
export var marketing__header = "zeal-pos-module--marketing__header--c09d5";
export var marketing__img = "zeal-pos-module--marketing__img--380ce";
export var marketing__wrapper = "zeal-pos-module--marketing__wrapper--cb56e";
export var physicalRetailer__container = "zeal-pos-module--physicalRetailer__container--70d4a";
export var physicalRetailer__content = "zeal-pos-module--physicalRetailer__content--22dcc";
export var physicalRetailer__item = "zeal-pos-module--physicalRetailer__item--3cfc8";
export var physicalRetailer__wrapper = "zeal-pos-module--physicalRetailer__wrapper--619c6";
export var posJourney__content = "zeal-pos-module--posJourney__content--2cb5c";
export var posJourney__features = "zeal-pos-module--posJourney__features--524db";
export var posJourney__header = "zeal-pos-module--posJourney__header--0ce62";
export var posJourney__img = "zeal-pos-module--posJourney__img--638ad";
export var posJourney_container = "zeal-pos-module--posJourney_container--acd52";
export var posTerminals__images = "zeal-pos-module--posTerminals__images--b4a8c";
export var posTerminals__title = "zeal-pos-module--posTerminals__title--e6d3b";
export var posTerminals__wrapper = "zeal-pos-module--posTerminals__wrapper--2cf97";
export var retailer__img = "zeal-pos-module--retailer__img--3c1a5";
export var retain__btn = "zeal-pos-module--retain__btn--71d00";
export var retain__content = "zeal-pos-module--retain__content--84957";
export var retain__description = "zeal-pos-module--retain__description--26cfc";
export var retain__description2 = "zeal-pos-module--retain__description2--e02c1";
export var retain__div__btn = "zeal-pos-module--retain__div__btn--85b68";
export var retain__header = "zeal-pos-module--retain__header--5450d";
export var retain__wrapper = "zeal-pos-module--retain__wrapper--2858b";
export var textCenter = "zeal-pos-module--textCenter--df97f";
export var zealApp__container = "zeal-pos-module--zealApp__container--16cb4";
export var zealApp__giftImg = "zeal-pos-module--zealApp__giftImg--d29e1";
export var zealApp__img = "zeal-pos-module--zealApp__img--d2f44";
export var zealApp__imgsContainer = "zeal-pos-module--zealApp__imgsContainer--58534";
export var zealApp__productsImg = "zeal-pos-module--zealApp__productsImg--7d451";
export var zealApp__typesImg = "zeal-pos-module--zealApp__typesImg--ce7e7";
export var zealApp__wrapper = "zeal-pos-module--zealApp__wrapper--a28c8";