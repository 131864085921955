import * as React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link, useI18next } from "gatsby-plugin-react-i18next";
import { useLocation } from "@reach/router";

import { motion } from "framer-motion";
import { ThreeDots } from "react-loader-spinner";
import toast, { Toaster } from "react-hot-toast";
// Components
import Seo from "../../components/SEO";
import Layout from "../../components/Layout";

// Assets
import posHeader from "../../images/pos-hero.png";
import VerifoneLogo from "../../images/pos/terminals/verifone.png";
import IngenicoLogo from "../../images/pos/terminals/ingenico.png";
import UrovoLogo from "../../images/pos/terminals/urovo.png";
import SunmiLogo from "../../images/pos/terminals/sunmi.png";
import PaxLogo from "../../images/pos/terminals/pax.png";
import customer from "../../images/pos/customerIcons/customer.svg";
import segment from "../../images/pos/customerIcons/segment.svg";
import gift from "../../images/pos/customerIcons/gift.svg";
import revenue from "../../images/pos/customerIcons/revenue.svg";
import machine from "../../images/pos/journyIcons/machine.png";
import loyalty from "../../images/pos/journyIcons/loyalty.svg";
import transactions from "../../images/pos/journyIcons/transactions.svg";
import basket from "../../images/pos/journyIcons/basket.svg";
import rewards from "../../images/pos/journyIcons/rewards.svg";
import vas from "../../images/pos/journyIcons/vas.svg";
import terminals from "../../images/pos/journyIcons/terminals.svg";
import retail from "../../images/pos/journyIcons/retail.svg";
import interchange from "../../images/pos/journyIcons/interchange.svg";
import step1 from "../../images/pos/firstJourney/step1.png";
import s1mob from "../../images/pos/firstJourney/s1mob.png";
import step2 from "../../images/pos/firstJourney/step2.png";
import s2mob from "../../images/pos/firstJourney/s2mob.png";
import step3 from "../../images/pos/firstJourney/step3.png";
import s3mob from "../../images/pos/firstJourney/s3mob.png";
import step4 from "../../images/pos/firstJourney/step4.png";
import s4mob from "../../images/pos/firstJourney/s4mob.png";
import firstStep1 from "../../images/pos/firstStep1.png";
import st1mob from "../../images/pos/st1mob.png";
import firstStep2 from "../../images/pos/firstStep2.png";
import st2mob from "../../images/pos/st2mob.png";
import retain from "../../images/pos/retain.png";
import zealApp from "../../images/pos/zeal-app.png";
import points from "../../images/pos/loyalty_analytics/points.svg";
import brand from "../../images/pos/loyalty_analytics/brand.svg";
import analytics from "../../images/pos/loyalty_analytics/analytics.svg";
import appGift from "../../images/pos/loyalty_analytics/gift.svg";
import buy from "../../images/pos/loyalty_analytics/buy.svg";
import types from "../../images/pos/types.png";
import giftFriend from "../../images/pos/gift-friend.png";
import products from "../../images/pos/product-card.png";
import customers from "../../images/pos/retailerIcons/customers.svg";
import newCustomers from "../../images/pos/retailerIcons/newCustomers.svg";
import retargetCustomer from "../../images/pos/retailerIcons/retargetCustomer.svg";
import retainCustomer from "../../images/pos/retailerIcons/retainCustomer.svg";
import retailer from "../../images/pos/retailer.png";
import marketing from "../../images/pos/marketing.png";
import gdpr from "../../images/pos/securityIcons/gdpr.svg";
import ssl from "../../images/pos/securityIcons/ssl.svg";
import token from "../../images/pos/securityIcons/token.svg";
import pci from "../../images/pos/securityIcons/pci.svg";
import dataSecurityImg from "../../images/pos/dataSecurity.png";
import bookMockup from "../../images/pos/bookMockup.png";

// Hooks
// import useWindowSize from "../../hooks/useWindowSize";

// Styles
import * as styles from "../../styles/pages/zeal-pos.module.css";
import * as yStyles from "../../styles/components/YellowSection.module.css";

// Utils
import {
  revealContainerProps,
  revealContainerWithChildrenProps,
  revealVariant,
} from "../../utils/animation-variants";
import useScreenSize from "../../hooks/useScreenSizes";
import styled from "styled-components";
import { api } from "../../utils/api";

const MotionLink = motion(Link);

const posTerminalIntegrated = [
  {
    id: "verifone",
    title: "Verifone",
    image: VerifoneLogo,
  },
  {
    id: "ingenico",
    title: "Ingenico",
    image: IngenicoLogo,
  },
  {
    id: "urovo",
    title: "Urovo",
    image: UrovoLogo,
  },
  {
    id: "sunmi",
    title: "Sunmi",
    image: SunmiLogo,
  },
  {
    id: "pax",
    title: "Pax",
    image: PaxLogo,
  },
];

const HeroSection = () => {
  const { t } = useTranslation();
  return (
    <section>
      <div className={styles.heroSection__wrapper}>
        <motion.div
          {...revealContainerWithChildrenProps}
          className={`${styles.heroSection__content} sectionContainer`}
        >
          <motion.div variants={revealVariant}>
            <motion.h1
              variants={revealVariant}
              className={styles.heroSection__title}
            >
              {t("pos.heroSection.title")}
            </motion.h1>
            <motion.p
              variants={revealVariant}
              className={styles.heroSection__description}
            >
              {t("pos.heroSection.description")}
            </motion.p>
            <motion.div
              variants={revealVariant}
              className={styles.heroSection__button__wrapper}
            >
              <MotionLink
                to="/products/zeal-pos"
                className={`${styles.heroSection__button} button button--primary`}
                onClick={() => {
                  window.open("https://s.getzeal.io/SmartPOSLicense", "_blank");
                }}
              >
                {t("pos.btn.get.license")}
              </MotionLink>

              <MotionLink
                to="/products/zeal-pos"
                onClick={() => {
                  window.open(
                    "https://youtu.be/j5DZjK8hRPU?si=MGcDWfC-wljJ-WKc",
                    "_blank"
                  );
                }}
                style={{
                  color: "#FFF",
                  fontSize: "22px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "150%",
                  textDecorationLine: "underline",
                  padding: "16px 35px",
                }}
              >
                {t("pos.btn.learn.more")}
              </MotionLink>
            </motion.div>
          </motion.div>

          <motion.div
            variants={revealVariant}
            className={styles.heroSection__hero_img_desktop}
          >
            {/* <motion.img
              alt="Zeal Pay"
              src={posEllipse}
              formats={["auto", "webp", "avif"]}
              className={styles.heroSection__ellipse}
            /> */}
            <motion.img
              alt="Zeal Pay"
              src={posHeader}
              formats={["auto", "webp", "avif"]}
              className={styles.heroSection__backgroundImg}
            />{" "}
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

const CustomerSection = () => {
  const { t } = useTranslation();
  const posContent = [
    {
      id: "customer",
      title: t("pos.customerId.title"),
      image: customer,
      content: t("pos.customerId.description"),
    },
    {
      id: "segment",
      title: t("pos.segmentCustomer.title"),
      image: segment,
      content: t("pos.segmentCustomer.description"),
    },
    {
      id: "offer",
      title: t("pos.customerOffers.title"),
      image: gift,
      content: t("pos.customerOffers.description"),
    },
    {
      id: "revenue",
      title: t("pos.revenue.title"),
      image: revenue,
      content: t("pos.revenue.description"),
    },
  ];
  return (
    <section className={styles.customerSection}>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={` sectionContainer`}
      >
        <motion.div>
          <motion.h1 className={styles.customerSection__title}>
            {t("pos.customerSection.title")}
          </motion.h1>
          <motion.p className={styles.customerSection__description}>
            {t("pos.customerSection.description")}
          </motion.p>
          <motion.p className={styles.customerSection__description2}>
            {t("pos.customerSection.description2")}
          </motion.p>
        </motion.div>
        <motion.div className={styles.customerSection__content}>
          {posContent.map((item) => (
            <motion.div key={item.id} className={styles.customerSection__item}>
              <motion.img src={item.image} />
              <motion.div>
                <motion.h2 className={styles.customerSection__itemHeader}>
                  {item.title}
                </motion.h2>
                <motion.p className={styles.customerSection__itemContent}>
                  {item.content}
                </motion.p>
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
        <motion.div className={styles.posTerminals__wrapper}>
          <motion.h2
            {...revealContainerProps}
            variants={revealVariant}
            className={styles.posTerminals__title}
          >
            {t("pos.terminals.integrated")}
          </motion.h2>
          <motion.div
            {...revealContainerWithChildrenProps}
            variants={revealVariant}
            className={styles.posTerminals__images}
          >
            {posTerminalIntegrated.map((item) => (
              <motion.img
                variants={revealVariant}
                key={item.id}
                src={item.image}
                alt="Zeal"
              />
            ))}
          </motion.div>
        </motion.div>
      </motion.div>
    </section>
  );
};

const POSJourney = () => {
  const { t } = useTranslation();
  const posFeatures = [
    {
      id: "loyalty",
      image: loyalty,
      content: t("pos.journey.loyalty"),
    },
    {
      id: "transactions",
      image: transactions,
      content: t("pos.journey.transactions"),
    },
    {
      id: "basket",
      image: basket,
      content: t("pos.journey.basket"),
    },
    {
      id: "rewards",
      image: rewards,
      content: t("pos.journey.rewards"),
    },
    {
      id: "vas",
      image: vas,
      content: t("pos.journey.vas"),
    },
    {
      id: "terminals",
      image: terminals,
      content: t("pos.journey.terminal"),
    },
    {
      id: "retail",
      image: retail,
      content: t("pos.journey.retailers"),
    },
    {
      id: "interchange",
      image: interchange,
      content: t("pos.journey.interchange"),
    },
  ];

  return (
    <section>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`${styles.posJourney_container} sectionContainer`}
      >
        <motion.div className={styles.posJourney__content}>
          <motion.h1
            {...revealContainerProps}
            variants={revealVariant}
            className={styles.posJourney__header}
          >
            {t("pos.journey.title")}
          </motion.h1>
          <motion.img src={machine} className={styles.posJourney__img} />
        </motion.div>
        <motion.div
          {...revealContainerWithChildrenProps}
          className={styles.posJourney__features}
        >
          {posFeatures.map((feat) => (
            <motion.div key={feat.id} className={styles.customerSection__item}>
              <motion.img src={feat.image} />
              <motion.div>
                <motion.p className={styles.customerSection__itemContent}>
                  {feat.content}
                </motion.p>
              </motion.div>
            </motion.div>
          ))}
          <motion.div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
              alignItems: "center",
              columnSpan: "all",
              gridColumn: "span 2",
            }}
          >
            <MotionLink
              style={{
                maxWidth: "350px",
              }}
              to="/products/zeal-pos"
              className={`${styles.heroSection__button} button button--primary`}
              onClick={() => {
                window.open("https://s.getzeal.io/SmartPOSLicense", "_blank");
              }}
            >
              {t("pos.btn.buy.license")}
            </MotionLink>
          </motion.div>
        </motion.div>
      </motion.div>
    </section>
  );
};

const TransactionJourney = () => {
  const { t } = useTranslation();
  const { isLG } = useScreenSize();
  const firstJourney = [
    {
      id: "1",
      image: step1,
      mobImg: s1mob,
      content: t("pos.journey.step1"),
    },
    {
      id: "2",
      image: step2,
      mobImg: s2mob,
      content: t("pos.journey.step2"),
    },
    {
      id: "3",
      image: step3,
      mobImg: s3mob,
      content: t("pos.journey.step3"),
    },
    {
      id: "4",
      image: step4,
      mobImg: s4mob,
      content: t("pos.journey.step4"),
    },
  ];

  return (
    <section>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`${styles.posJourney_container} sectionContainer`}
      >
        <motion.div
          {...revealContainerWithChildrenProps}
          className={styles.firstJourney__features}
        >
          <motion.h1
            {...revealContainerProps}
            variants={revealVariant}
            className={styles.firstJourney__header}
          >
            {t("pos.journey.steps")}
          </motion.h1>
          <motion.div className={styles.firstJourney__wrapper}>
            {firstJourney.map((feat) => (
              <motion.div className={styles.firstJourney__content}>
                <motion.img src={isLG ? feat.image : feat.mobImg} />
                <motion.div
                  key={feat.id}
                  className={styles.firstJourney__text}
                  {...revealContainerProps}
                  variants={revealVariant}
                >
                  <motion.p className={styles.firstJourney__id}>
                    {feat.id}
                  </motion.p>
                  <motion.p className={styles.firstJourney__p}>
                    {feat.content}
                  </motion.p>
                </motion.div>
              </motion.div>
            ))}{" "}
          </motion.div>

          <motion.div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
              alignItems: "center",
            }}
          >
            <MotionLink
              style={{
                maxWidth: "350px",
              }}
              to="/products/zeal-pos"
              className={`${styles.heroSection__button} button button--primary`}
              onClick={() => {
                window.open("https://s.getzeal.io/SmartPOSLicense", "_blank");
              }}
            >
              {t("pos.btn.buy.license")}
            </MotionLink>
          </motion.div>
        </motion.div>
      </motion.div>
    </section>
  );
};

const FirstTimeJourney = () => {
  const { t } = useTranslation();
  const { isMD } = useScreenSize();
  return (
    <section>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`${styles.firstTime__container} sectionContainer`}
      >
        <motion.div className={styles.firstTime__wrapper}>
          <motion.h1 className={styles.firstTime__header}>
            {t("pos.feat.transaction")}
          </motion.h1>{" "}
        </motion.div>
        <motion.div className={`${styles.firstTime__images}  sectionContainer`}>
          <motion.div className={styles.firstTime__content}>
            <motion.img
              style={{ maxWidth: "292px", maxHeight: "485px" }}
              src={isMD ? firstStep1 : st1mob}
            />
            <motion.p
              className={styles.firstTime__info}
              style={{ maxWidth: "340px" }}
            >
              {t("pos.journey.step1")}
            </motion.p>
          </motion.div>
          <motion.div className={styles.firstTime__content}>
            <motion.img
              style={{ maxWidth: "245px", maxHeight: "485px" }}
              src={isMD ? firstStep2 : st2mob}
            />
            <motion.p
              className={styles.firstTime__info}
              style={{ maxWidth: "375px" }}
            >
              {t("pos.feat.transaction.step2")}
            </motion.p>
          </motion.div>{" "}
        </motion.div>
        <motion.div className={styles.firstTime__wrapper}>
          <MotionLink
            to="/products/zeal-pos"
            className={styles.firstTime__btn}
            onClick={() => {
              window.open("https://s.getzeal.io/SmartPOSLicense", "_blank");
            }}
          >
            {t("pos.feat.transaction.btn")}
          </MotionLink>
        </motion.div>
      </motion.div>
    </section>
  );
};

const RetainContent = () => {
  const { t } = useTranslation();
  return (
    <section>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`${styles.retain__wrapper} sectionContainer`}
      >
        <motion.div className={styles.retain__content}>
          <motion.h1 className={styles.retain__header}>
            {t("pos.retain.customer")}
          </motion.h1>
          <motion.p className={styles.retain__description}>
            {t("pos.retain.description1")}
          </motion.p>
          <motion.p className={styles.retain__description}>
            {t("pos.retain.description2")}
          </motion.p>
          <motion.div className={styles.retain__div__btn}>
            <MotionLink
              to="/products/zeal-pos"
              className={styles.retain__btn}
              onClick={() => {
                window.open("https://s.getzeal.io/SmartPOSLicense", "_blank");
              }}
            >
              {t("pos.retain.btn")}
            </MotionLink>
          </motion.div>
        </motion.div>
        <motion.div>
          <motion.img src={retain} />
        </motion.div>
      </motion.div>
    </section>
  );
};

const DualLoyaltySection = () => {
  const { t } = useTranslation();
  const loyalty = [
    {
      id: "1",
      image: points,
      title: t("pos.points.title"),
      content: t("pos.points.description"),
    },
    {
      id: "2",
      image: brand,
      title: t("pos.merchant.title"),
      content: t("pos.merchant.description"),
    },
  ];
  return (
    <section>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`${styles.loyalty__wrapper} sectionContainer`}
      >
        <motion.img
          src={zealApp}
          className={`${styles.loyalty__img} elevetion-animation`}
        />
        <motion.div className={styles.loyalty__container}>
          <motion.h1 className={styles.loyalty__header}>
            {t("pos.dual.loyalty")}
          </motion.h1>
          {loyalty.map((item) => (
            <motion.div key={item.id} className={styles.customerSection__item}>
              <motion.div className={styles.loyalty__imgContainer}>
                <motion.img src={item.image} />
              </motion.div>
              <motion.div>
                <motion.h2 className={styles.customerSection__itemHeader}>
                  {item.title}
                </motion.h2>
                <motion.p className={styles.loyalty__itemContent}>
                  {item.content}
                </motion.p>
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </section>
  );
};

const ZealAppBenefits = () => {
  const { t } = useTranslation();
  const benefits = [
    {
      id: "1",
      image: analytics,
      title: t("pos.analytics.title"),
      content: t("pos.analytics.description"),
    },
    {
      id: "2",
      image: appGift,
      title: t("pos.gifts.title"),
      content: t("pos.gifts.description"),
    },
    {
      id: "3",
      image: buy,
      title: t("pos.advanced.buy.title"),
      content: t("pos.advanced.buy.description"),
    },
  ];
  return (
    <section>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`${styles.zealApp__wrapper} sectionContainer`}
      >
        <motion.div
          className={styles.zealApp__container}
          {...revealContainerWithChildrenProps}
        >
          <motion.h1 className={styles.loyalty__header}>
            {t("pos.zeal.benefits")}
          </motion.h1>
          {benefits.map((item) => (
            <motion.div key={item.id} className={styles.customerSection__item}>
              <motion.div className={styles.loyalty__imgContainer}>
                <motion.img src={item.image} />
              </motion.div>
              <motion.div>
                <motion.h2 className={styles.customerSection__itemHeader}>
                  {item.title}
                </motion.h2>
                <motion.p className={styles.loyalty__itemContent}>
                  {item.content}
                </motion.p>
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
        <motion.div className={styles.zealApp__imgsContainer}>
          <motion.img src={zealApp} className={styles.zealApp__img} />
          <motion.img
            src={products}
            className={`${styles.zealApp__productsImg} elevetion-animation`}
          />
          <motion.img
            src={types}
            className={`${styles.zealApp__typesImg} elevetion-animation`}
          />
          <motion.img
            src={giftFriend}
            className={`${styles.zealApp__giftImg} elevetion-animation`}
          />
        </motion.div>{" "}
      </motion.div>
    </section>
  );
};

const PhysicalRetailers = () => {
  const { t } = useTranslation();
  const { isMD } = useScreenSize();

  const retailers = [
    {
      id: "1",
      image: customers,
      title: t("pos.customers.title"),
      content: t("pos.customers.description"),
    },
    {
      id: "2",
      image: newCustomers,
      title: t("pos.new.customers.title"),
      content: t("pos.new.customers.description"),
    },
    {
      id: "3",
      image: retainCustomer,
      title: t("pos.retain.customers.title"),
      content: t("pos.retain.customers.description"),
    },
    {
      id: "4",
      image: retargetCustomer,
      title: t("pos.retarget.customers.title"),
      content: t("pos.retarget.customers.description"),
    },
  ];
  return (
    <section
      {...revealContainerWithChildrenProps}
      className={styles.physicalRetailer__wrapper}
    >
      <motion.div className={styles.physicalRetailer__container}>
        <motion.div
          className={`${styles.retain__content} ${styles.physicalRetailer__content}`}
        >
          <motion.h1
            className={`${styles.retain__header} ${
              isMD ? "" : styles.textCenter
            }`}
          >
            {" "}
            {t("pos.retails.header")}
          </motion.h1>

          <motion.p
            className={`${styles.retain__description} ${
              isMD ? "" : styles.textCenter && styles.retain__description2
            }`}
          >
            {t("pos.retails.content")}
          </motion.p>
          <motion.div className={styles.physicalRetailer__item}>
            {retailers.map((item) => (
              <motion.div
                key={item.id}
                className={styles.customerSection__item}
              >
                <motion.div className={styles.loyalty__imgContainer}>
                  <motion.img src={item.image} />
                </motion.div>
                <motion.div>
                  <motion.h2 className={styles.customerSection__itemHeader}>
                    {item.title}
                  </motion.h2>
                  <motion.p className={styles.loyalty__itemContent}>
                    {item.content}
                  </motion.p>
                </motion.div>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
        <motion.div className={isMD ? "" : styles.fullWidth}>
          <motion.img src={retailer} className={styles.retailer__img} />
        </motion.div>
      </motion.div>
    </section>
  );
};

const MarketingCampaigns = () => {
  const { t } = useTranslation();
  return (
    <section>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`${styles.marketing__wrapper} sectionContainer`}
      >
        <motion.div className={styles.marketing__img}>
          <motion.img src={marketing} />
        </motion.div>

        <motion.div className={`${styles.retain__content} ${styles.marketing}`}>
          <motion.h1 className={styles.marketing__header}>
            {t("pos.marketing.header")}
          </motion.h1>
          <motion.p className={styles.marketing__description}>
            {t("pos.marketing.content")}
          </motion.p>

          <motion.div
            className={`${styles.customerSection__item} ${styles.marketing__content} !justify-start`}
          >
            <motion.div className={styles.loyalty__imgContainer}>
              <motion.img src={revenue} />
            </motion.div>
            <motion.div>
              <motion.h2 className={styles.customerSection__itemHeader}>
                {t("pos.marketing.revenue.title")}
              </motion.h2>
              <motion.p className={styles.loyalty__itemContent}>
                {t("pos.marketing.revenue.description")}
              </motion.p>
            </motion.div>
          </motion.div>

          <MotionLink
            to="/products/zeal-pos"
            className={styles.retain__btn}
            onClick={() => {
              window.open("https://s.getzeal.io/SmartPOSLicense", "_blank");
            }}
          >
            {t("pos.contact.us.btn")}
          </MotionLink>
        </motion.div>
      </motion.div>
    </section>
  );
};

const DataSecurity = () => {
  const { t } = useTranslation();

  const dataSecurity = [
    {
      id: "1",
      image: gdpr,
      title: t("pos.gdpr.title"),
      content: t("pos.gdpr.description"),
    },
    {
      id: "2",
      image: ssl,
      title: t("pos.ssl.title"),
      content: t("pos.ssl.description"),
    },
    {
      id: "3",
      image: token,
      title: t("pos.token.title"),
      content: t("pos.token.description"),
    },
    // {
    //   id: "4",
    //   image: pci,
    //   title: t("pos.pci.title"),
    //   content: t("pos.pci.description"),
    // },
  ];
  return (
    <section
      {...revealContainerWithChildrenProps}
      className={styles.physicalRetailer__wrapper}
    >
      <motion.div className={styles.dataSecurity__container}>
        <motion.div className={styles.retain__content}>
          <motion.h1 className={styles.dataSecurity__header}>
            {t("pos.data.security.header")}
          </motion.h1>
          <motion.p className={styles.dataSecurity__content}>
            {t("pos.data.security.content")}
          </motion.p>
          <motion.div className={styles.dataSecurity__items}>
            {dataSecurity.map((item) => (
              <motion.div key={item.id} className={styles.dataSecurity__item}>
                <motion.div className={styles.loyalty__imgContainer}>
                  <motion.img src={item.image} />
                </motion.div>
                <motion.div>
                  <motion.h2 className={styles.customerSection__itemHeader}>
                    {item.title}
                  </motion.h2>
                  <motion.p className={styles.loyalty__itemContent}>
                    {item.content}
                  </motion.p>
                </motion.div>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
        <motion.div className={styles.zealApp__imgsContainer}>
          <motion.img src={dataSecurityImg} className={styles.retailer__img} />
        </motion.div>
      </motion.div>
    </section>
  );
};

const BookMockUp = () => {
  const { t } = useTranslation();
  const { language } = useI18next();

  const [email, setEmail] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const location = useLocation();
  const fullUrl = location.href;

  const submitEmail = async () => {
    setIsLoading(true);
    const response = await fetch(`${api}/v3/ebook`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-Language": language,
      },
      body: JSON.stringify({
        email: email,
        url: fullUrl,
      }),
    });
    if (response.status === 200) {
      if (window && window.dataLayer) {
        window.dataLayer.push({
          event: "formSubmitted",
          formName: "LG_BL_Plugin",
          formPosition: "body",
        });
      }
      toast.success("Check your email! 😍🚀");
      setEmail("");
    }
    setIsLoading(false);
  };

  return (
    <section {...revealContainerWithChildrenProps}>
      <motion.div className={styles.bookMockUp__wrapper}>
        <motion.div className={styles.bookMockUp__imgContainer}>
          <motion.img src={bookMockup} />
        </motion.div>

        <motion.div className={`${styles.bookMockUp__container}`}>
          <motion.h1
            variants={revealVariant}
            className={styles.bookMockUp__header}
          >
            {t("pos.book.header")}
          </motion.h1>
          <motion.p
            variants={revealVariant}
            className={styles.bookMockUp__content}
          >
            {t("pos.book.content")}
          </motion.p>
          <motion.div className={styles.fullWidth}>
            <motion.h2 variants={revealVariant}>
              {t("pos.email.label")}
              <motion.span style={{ color: "red" }}>*</motion.span>
            </motion.h2>
            <motion.input
              type="email"
              placeholder={t("pos.email.placeholder")}
              className={`${styles.fullWidth} ${styles.bookMockUp__input}`}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </motion.div>
          <motion.button
            className={`${yStyles.button} button button--secondary ${styles.fullWidth}`}
            onClick={submitEmail}
            disabled={isLoading}
          >
            {isLoading ? (
              <CenteredContent>
                <ThreeDots
                  height="28"
                  width="28"
                  color="#fff"
                  ariaLabel="loading"
                  radius="3"
                  visible={true}
                />
              </CenteredContent>
            ) : (
              t("pos.book.btn")
            )}
          </motion.button>
          <Toaster />
        </motion.div>
      </motion.div>
    </section>
  );
};

const ZealPOS = () => {
  return (
    <Layout>
      <HeroSection />
      <CustomerSection />
      <POSJourney />
      <TransactionJourney />
      <FirstTimeJourney />
      <RetainContent />
      <DualLoyaltySection />
      <ZealAppBenefits />
      <PhysicalRetailers />
      <MarketingCampaigns />
      <DataSecurity />
      <BookMockUp />
    </Layout>
  );
};

export default ZealPOS;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "zeal-pos"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const Head = ({ pageContext }) => {
  const isArabic = pageContext.i18n.language === "ar";
  return <Seo isArabic={isArabic} title={isArabic ? "Zeal POS" : "Zeal POS"} />;
};

export const CenteredContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
